import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <p className="mb-0 tp-section-subtitle">
                    All About ALOK
                  </p>
                  <h2 className="mb-0 tp-pt-size">
                    We make plastics safer, affordable and sustainable
                  </h2>
                </div>
                <div className="about-tb-content">
                
                <p className="mb-0 about-para">
                ALOK is a leading masterbatch provider, co-creating innovative, reliable and responsible applications for the plastic industry. Over the last few years we have put innovation and sustainability at the core of our existence. Today, with an amalgamation of research, world class manufacturing processes and an experienced team, we deliver high quality, consistent and cost effective solutions to cater to the current and future needs of our customers for both standard and speciality solutions.
                  </p>
                      {/* <p className="mb-0 about-para">
                    The journey of ALOK Masterbatches began under the joint leadership of C.S.Bharuria and K.L.Puri in 1993 with a shared vision to bridge the technology gap of the Indian Plastic/ Polymer Industry. It had a humble start with a small team of passionate individuals and today has grown into a thriving masterbatch industry leader.
                      </p>
                      <br/> */}
                      {/* <br/> */}
                      {/* <p className="mb-0 about-para">
                    The reins of the company have been handed over to the second generation leaders Vikram Bhaduria, Amit Puri and Aditya Bhaduria in 1999 who have inherited the founder's mindset. The trio embody a unique blend of unwavering passion, insatiable curiosity leading to innovation, a deep appreciation for meaningful relationships, and an unyielding commitment to integrity, setting for ALOK success with a strong foundation of values.
                      </p>
                      <br/> */}
                      {/* <br/> */}
                      {/* <p className="mb-0 about-para">
                    Ever since ALOK has been in the forefront of technological advancement, transforming & shaping the Indian plastic/ polymer industry in India. ALOK believes in harnessing the power of technology to simplify complexity, enable possibilities & elevate it’s clientele experiences.
                      </p>
                      <br/> */}
                      {/* <br/> */}
                     
                    

                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end h-100">
              <div className="tp-about-img-box p-relative d-inline-block h-100">
                <div className="about-page-img">
                  <img src="assets/img/about/about-details-image.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;

import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Products
                        </h3>
                        <ul>
                          <li>
                            <a href="/additive-masterbatches">Additive Masterbatches</a>
                          </li>
                          <li>
                            <a href="/white-masterbatches">White Masterbatches</a>
                          </li>
                          <li>
                            <a href="/black-masterbatches">Black Masterbatches</a>
                          </li>
                          <li>
                            <a href="/mineral-masterbatches">Mineral Masterbatches</a>
                          </li>
                          <li>
                            <a href="/uv-masterbatches">UV Masterbatches</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Industries</h3>
                        <ul>
                          <li>
                            <a href="/industries/agriculture">Agriculture</a>
                          </li>
                          <li>
                            <a href="/industries/automotive">Automotive</a>
                          </li>
                          <li>
                            <a href="/industries/building-and-contruction-industry">Building And Construction</a>
                          </li>
                          <li>
                            <a href="/industries/consumer-durables-industry">Consumer Durables</a>
                          </li>
                          <li>
                            <a href="/industries/packaging-industry">Packaging Industries</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <a href="/sustainability">Sustainability</a>
                          </li>
                          <li>
                            <a href="/regulatory">Regulatory</a>
                          </li>
                          <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="/terms-and-conditions">Terms & Condition</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Our Contact Details</h3>
                        <ul>
                          <li>
                            <a href="https://maps.app.goo.gl/3S627CyWchT8dkr3A">
                            227, Okhla Industrial Estate, Phase III, New Delhi -110020.
                            </a>
                          </li>
                          <li>
                            <a href="tel:+911141612244">+91-11-41612244-47</a>
                          </li>
                          <li>
                            <a href="mailto:support@example.com">
                              info@alokindustries.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} ALOK . All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;

import React from 'react'




export default function OurValues() {

    const values = [
        {
            icon: "/assets/img/values/1.svg",
            title: "We are Passionate",
            desc: "We believe that Chemistry can make the world a better place",
            color: "#5b61aa"
        },
        {
            icon: "/assets/img/values/2.svg",
            title: "We are Curious",
            desc: "We challenge the Status Quo",
            color: "#CA1A66"
        },
        {
            icon: "/assets/img/values/3.svg",
            title: "We are Honest",
            desc: "We deliver on what we promise and add value beyond what is expected",
            color: "#F47D41"
        },
        {
            icon: "/assets/img/values/4.svg",
            title: "We Value Relationships",
            desc: "We believe that our partners, customers and employees are the reasons for our success",
            color: "#119D48"
        },
    ]

    return (
        <div className='container-fluid our-values'>

            <div className='container our-values-container'>
                <div className='our-values-title-container'>
                    <h5 className='title m-0 p-0'>Our values</h5>
                    {/* <h3 className='subtitle m-0 p-0'>Combining the Power of Chemistry with Technology</h3> */}
                </div>

                <div className='divider'></div>

                <div className='values row g-3'>
                    {
                        values.map((value, index) => (
                            <div className='col-12 col-md-6 col-lg-3'>
                            <div className='value ' key={index}>
                                <img src={value.icon} className='icon'/>
                                <h5 className='title m-0 p-0' style={{color: value.color}}>{value.title}</h5>
                                <p className='desc m-0 p-0'>{value.desc}</p>
                            </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
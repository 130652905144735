


export default function IndustrySidebar({ allowed = [] }) {

    const products = [{
        name: "Additive Masterbatches",
        image: "/assets/img/products/additive-masterbatch.jpg",
        description: "Additive Masterbatches are designed to enhance the performance, durability, and aesthetics of plastics across a broad spectrum of industries.",
        link: "/additive-masterbatches",
        id: 1
    },
    {
        name: "Black Masterbatches",
        image: "/assets/img/products/black.jpg",
        description: "ALOK’s range of Black Masterbatches are considered the Gold Standard in the industry.",
        link: "/black-masterbatches",
        id: 2
    },
    {
        name: "UV Masterbatches",
        image: "/assets/img/products/uv.jpg",
        description: "As India's largest manufacturer of UV Masterbatches, ALOK provides unparalleled expertise in light stabilization of polymers.",
        link: "/uv-masterbatches",
        id: 3
    },
    {
        name: "Color Masterbatches",
        image: "/assets/img/products/color.jpg",
        description: "Our Color Masterbatches and Single Pigment Concentrates are crafted to meet diverse technical and creative needs across industries.",
        link: "/color-masterbatches",
        id: 4
    },
    {
        name: "Fibre Masterbatches",
        image: "/assets/img/products/textile.jpg",
        description: "ALOK Masterbatches leads the way in developing specialized masterbatches for the fibers and nonwovens industry, offering tailored solutions that enhance the properties of fibers and nonwoven fabrics.",
        link: "/fibre-masterbatches",
        id: 5
    },
    {
        name: "White Masterbatches",
        image: "/assets/img/products/white.jpg",
        description: "White Masterbatches offer unparalleled performance with up to 75% titanium dioxide (TiO2) concentrations, ensuring high opacity, excellent dispersion, and consistency.",
        link: "/white-masterbatches",
        id: 6
    },
    {
        name: "Mineral Masterbatches",
        image: "/assets/img/products/mineral.jpg",
        description: "Alok has developed high performance mineral reinforced Masterbatches specially for PP Tapes applications.",
        link: "/mineral-masterbatches",
        id: 7
    },
    {
        name: "Agriculture Masterbatches",
        image: "/assets/img/products/agriculture.jpg",
        description: "Alok has developed a sophisticated range of Masterbatches specifically tailored for agricultural films.",
        link: "/agriculture-masterbatches",
        id: 8
    },
    {
        name: "Recycling Masterbatches",
        image: "/assets/img/products/recycling.jpg",
        description: "ALOK’s RE- NEW Masterbatches are specifically designed for the recycling industry to enhance the quality and usability of recycled plastics.",
        link: "/recycling-masterbatches",
        id: 9
    }
    ]

    return (
        <div className="products-list">

            <div className="title-container">
                <p className="mb-0 title">Products</p>
            </div>


            <div className="packaging-products">


                {products.map((element, index) => {
                    if (allowed.includes(element.id)) {
                        return <div className="product" key={index}>
                            <div className="image-container">
                                <img src={element.image} />
                            </div>
                            <div className="details">
                                <p className="title mb-0">{element.name}</p>
                                <p className="description mb-0">
                                    {element.description}
                                    <a href={element.link}>...read more</a>
                                </p>
                            </div>
                        </div>
                    }
                })}
            </div>

        </div>
    )
}



import AppNavigation from "./app-navigation/AppNavigation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const appStyle = {
    // transform: 'scale(0.8)',
    // transformOrigin: 'top left',
    // width: '125%',
    // height: '125%',
    // position: 'absolute'
    // overflow: "hidden"
  };

  return (
    <div style={appStyle}>
      <ToastContainer/>
      <AppNavigation />
    </div>
  );
}

export default App;

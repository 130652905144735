import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import MobileMenus from "./MobileMenus";

const OffCanvas = ({
  setOffCanvas,
  isOffCanvas,
  showProducts,
  setShowProducts,
  showIndustries,
  setShowIndustries,
}) => {
  return (
    <>
      <div
        className={`offcanvas__area  ${isOffCanvas ? "opened" : ""
          } w-100 h-100 p-0 d-flex justify-content-between`}
      >
        <div className="submenu-outer-container overflow-auto">
          {showIndustries && (
            <div className={"submenu-container d-none d-lg-flex"}>
              <div className="industries-submenu">
                <h2 className="title">Industries</h2>
                <div className="industries-submenu-container row">
                  <Link
                    to={"/industries/automotive"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#622466" />
                        <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                        <path d="M3.1123 30.9111V21.0815C6.64831 22.1946 9.93017 23.8682 12.8512 25.9965C9.93017 28.1252 6.64831 29.7981 3.1123 30.9111Z" fill="white" />
                        <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                        <path d="M37.1139 21.0815V30.9111C33.5783 29.7981 30.2961 28.1252 27.375 25.9965C30.2961 23.8685 33.5783 22.1946 37.1139 21.0815Z" fill="white" />
                        <path d="M20.1148 18.7842C17.9494 15.8489 16.2582 12.5514 15.1553 8.99977L15.1563 8.99837H25.0741L25.0751 8.99977C23.9718 12.5514 22.2806 15.8489 20.1148 18.7842Z" fill="white" />
                      </svg> */}
                      <img src="/assets/img/industries/automobile1.jpg" style={{ objectFit: "cover" }} />
                    </div>

                    <p className="industry-name">Automotive</p>
                  </Link>

                  <Link
                    to={"/industries/agriculture"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#595DAA" />
                        <path d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z" fill="white" />
                        <path d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z" fill="white" />
                        <path d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z" fill="white" />
                        <path d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z" fill="white" />
                        <path d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z" fill="white" />
                      </svg> */}

                      <img src="/assets/img/industries/agriculture.jpg" />
                    </div>

                    <p className="industry-name">Agriculture</p>
                  </Link>

                  <Link
                    to={"/industries/packaging-industry"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#C41E63" />
                        <path d="M31.0683 14.9952C27.5167 16.0982 24.2192 17.7894 21.2839 19.9548C18.5302 17.9227 16.0951 15.4719 14.0713 12.6937C16.1992 9.77258 17.8729 6.49073 18.9859 2.95508H18.9919C21.441 8.30281 25.7456 12.5995 31.0683 14.9952Z" fill="white" />
                        <path d="M9.15527 2.95508L18.9849 2.95508C17.8718 6.49109 16.1982 9.77294 14.0699 12.694C11.9412 9.77294 10.2683 6.49109 9.15527 2.95508Z" fill="white" />
                        <path d="M21.2823 19.9551C24.2176 22.1208 27.5151 23.8121 31.0667 24.9154C25.744 27.3111 21.4394 31.6078 18.9903 36.9551H18.9843C17.8713 33.4198 16.1976 30.1373 14.0693 27.2166C16.0932 24.4384 18.5286 21.9879 21.2823 19.9551Z" fill="white" />
                        <path d="M18.9849 36.9557H9.15527C10.2683 33.4201 11.9413 30.1379 14.0699 27.2168C16.1979 30.1379 17.8718 33.4201 18.9849 36.9557Z" fill="white" />
                        <path d="M21.2822 19.9576C24.2175 17.7922 27.515 16.101 31.0666 14.998L31.068 14.9991V24.9169L31.0666 24.9179C27.515 23.8146 24.2175 22.1234 21.2822 19.9576Z" fill="white" />
                      </svg> */}

                      <img src="/assets/img/industries/packaging.jpg" />
                    </div>

                    <p className="industry-name">Packaging</p>
                  </Link>

                  <Link
                    to={"/industries/building-and-contruction-industry"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#F38336" />
                        <path d="M15.1514 30.913C16.2544 27.3614 17.9456 24.064 20.111 21.1286C18.0789 18.3749 15.6281 15.9399 12.8499 13.916C9.92883 16.044 6.64698 17.7176 3.11133 18.8307V18.8366C8.45906 21.2857 12.7557 25.5903 15.1514 30.913Z" fill="white" />
                        <path d="M3.11133 9V18.8296C6.64734 17.7166 9.92919 16.0429 12.8503 13.9146C9.92919 11.786 6.64734 10.1131 3.11133 9Z" fill="white" />
                        <path d="M20.1113 21.127C22.2771 24.0624 23.9683 27.3598 25.0716 30.9114C27.4674 25.5887 31.764 21.2841 37.1114 18.835V18.8291C33.5761 17.716 30.2935 16.0424 27.3728 13.9141C24.5946 15.9379 22.1441 18.3733 20.1113 21.127Z" fill="white" />
                        <path d="M37.112 18.8296V9C33.5763 10.1131 30.2941 11.786 27.373 13.9146C30.2941 16.0426 33.5763 17.7166 37.112 18.8296Z" fill="white" />
                        <path d="M20.1139 21.127C17.9485 24.0623 16.2573 27.3597 15.1543 30.9114L15.1554 30.9128H25.0731L25.0742 30.9114C23.9709 27.3597 22.2796 24.0623 20.1139 21.127Z" fill="white" />
                      </svg> */}

                      <img src="/assets/img/industries/building.jpg" />
                    </div>

                    <p className="industry-name">Building & Construction</p>
                  </Link>

                  <Link
                    to={"/industries/consumer-durables-industry"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#115E66" />
                        <path d="M15.1514 8.99907C16.2544 12.5507 17.9456 15.8481 20.111 18.7835C18.0789 21.5372 15.6281 23.9723 12.8499 25.9961C9.92883 23.8681 6.64698 22.1945 3.11133 21.0815V21.0755C8.45906 18.6264 12.7557 14.3218 15.1514 8.99907Z" fill="white" />
                        <path d="M3.11133 30.9121V21.0825C6.64734 22.1955 9.92919 23.8692 12.8503 25.9975C9.92919 28.1261 6.64734 29.7991 3.11133 30.9121Z" fill="white" />
                        <path d="M20.1113 18.7851C22.2771 15.8497 23.9683 12.5523 25.0716 9.00067C27.4674 14.3234 31.764 18.628 37.1114 21.0771V21.0831C33.5761 22.1961 30.2935 23.8697 27.3728 25.998C24.5946 23.9742 22.1441 21.5388 20.1113 18.7851Z" fill="white" />
                        <path d="M37.112 21.0825V30.9121C33.5763 29.7991 30.2941 28.1261 27.373 25.9975C30.2941 23.8695 33.5763 22.1955 37.112 21.0825Z" fill="white" />
                        <path d="M20.1139 18.7852C17.9485 15.8498 16.2573 12.5524 15.1543 9.00075L15.1554 8.99935H25.0731L25.0742 9.00075C23.9709 12.5524 22.2796 15.8498 20.1139 18.7852Z" fill="white" />
                      </svg> */}

                      <img src="/assets/img/industries/consumer.jpg" />
                    </div>

                    <p className="industry-name">Consumer Durables</p>
                  </Link>

                  <Link
                    to={"/industries/textile-and-fibers-industry"}
                    className="industry-item col-lg-4"
                  >
                    <div className="logo1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#8A8B35" />
                        <path d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z" fill="white" />
                        <path d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z" fill="white" />
                        <path d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z" fill="white" />
                        <path d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z" fill="white" />
                        <path d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z" fill="white" />
                      </svg> */}

                      <img src="/assets/img/industries/textile.jpg" />
                    </div>

                    <p className="industry-name">Textiles & Fibre</p>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {showProducts && (
            <div className={"submenu-container d-none d-lg-flex"}>
              <div className="products-submenu">
                <h2 className="title">Products</h2>
                <div className="products-submenu-container row">
                  <Link
                    to={"/additive-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/additive-masterbatch.jpg" className="image" />
                      <div className="product-submenu-details row">
                        <div className="col-3">
                          <p className="products-name mb-0">AM</p>
                        </div>
                        <div className="col-9">
                          <p className="products-desc mb-0">Additive Masterbatches</p>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/white-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/white.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">WM</p>
                        <p className="products-desc mb-0">White Masterbatches</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/black-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/black.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">BM</p>
                        <p className="products-desc mb-0">Black Masterbatches</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/mineral-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/mineral.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">MB</p>
                        <p className="products-desc mb-0">Mineral Masterbatches</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/uv-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/uv.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">UV</p>
                        <p className="products-desc mb-0">UV Masterbatches</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/agriculture-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/industries/agriculture.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">AH</p>
                        <p className="products-desc mb-0">
                          Masterbatches for Agriculture & Horticulture
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/color-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/color.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">CM</p>
                        <p className="products-desc mb-0">
                          Color Masterbatches & Single Pigment Concentrates
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/recycling-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/recycling.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">Re</p>
                        <p className="products-desc mb-0">
                          Masterbatches for Recycling
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to={"/fibre-masterbatches"}
                    className="products-item col-lg-6"
                  >
                    <div className="product-submenu">
                      <img src="/assets/img/products/textile.jpg" className="image" />
                      <div className="product-submenu-details">
                        <p className="products-name mb-0">FB</p>
                        <p className="products-desc mb-0">
                          Masterbatches for Fibers & Nonwovens
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`sideNav off-canvas-bg`}
        // style={{ backgroundImage: `url("assets/img/bg/Sidearea-bg-img.png")` }}
        >
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="offcanvas-logo">
              {/* <img src="/assets/img/logo/logo.png" alt="" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="40"
                viewBox="0 0 128 40"
                fill="none"
              >
                <path
                  d="M108.106 11.5144C105.669 8.06056 103.766 4.18278 102.525 0.00390625C99.8292 6.26561 94.9951 11.3296 88.9775 14.2113V14.2183C92.9562 15.5278 96.6489 17.496 99.9356 20.0004C103.061 17.6193 105.819 14.7545 108.106 11.5144Z"
                  fill="#F38336"
                />
                <path
                  d="M113.686 0.00390625C112.445 4.18237 110.542 8.06056 108.105 11.5144C110.392 14.7545 113.149 17.6193 116.275 20.0004C119.562 17.496 123.255 15.5278 127.233 14.2179V14.2113C121.216 11.3296 116.382 6.2652 113.686 0.00390625Z"
                  fill="#C41E63"
                />
                <path
                  d="M108.108 11.5142C110.544 8.06037 112.447 4.18259 113.689 0.00371309L113.688 0H102.528L102.527 0.00288237C103.768 4.18258 105.671 8.06037 108.108 11.5142Z"
                  fill="#E92644"
                />
                <path
                  d="M102.525 0.0039221L102.523 0H102.526L102.525 0.0039221Z"
                  fill="#9A9D9F"
                />
                <path
                  d="M113.684 0.00419772L113.683 0H113.686L113.684 0.00419772Z"
                  fill="#9A9D9F"
                />
                <path
                  d="M102.525 39.9988C103.766 35.8204 105.669 31.9409 108.105 28.4876C105.819 25.2478 103.061 22.383 99.9352 20.002C96.6485 22.5055 92.9558 24.4745 88.9775 25.784V25.791C94.9947 28.6723 99.8292 33.7367 102.525 39.9988Z"
                  fill="#1A9949"
                />
                <path
                  d="M88.9775 14.2188V25.7832C92.9562 24.4737 96.6489 22.5047 99.9356 20.0008C96.6489 17.4964 92.9562 15.5283 88.9775 14.2188Z"
                  fill="#8A8B35"
                />
                <path
                  d="M108.105 28.486C110.542 31.9394 112.445 35.8188 113.686 39.9973C116.382 33.7352 121.216 28.6708 127.233 25.7895V25.7825C123.255 24.473 119.562 22.5039 116.275 20C113.149 22.381 110.392 25.2463 108.105 28.486Z"
                  fill="#595DAA"
                />
                <path
                  d="M127.233 25.7832V14.2188C123.255 15.5283 119.562 17.4964 116.275 20.0008C119.562 22.5043 123.255 24.4737 127.233 25.7832Z"
                  fill="#622466"
                />
                <path
                  d="M108.108 28.4863C105.671 31.9397 103.768 35.8192 102.527 39.9976L102.528 39.9993H113.688L113.689 39.9976C112.447 35.8192 110.544 31.9397 108.108 28.4863Z"
                  fill="#115E66"
                />
                <path
                  d="M8.70038 10.2324L0 29.9781H4.52537L6.4587 25.7782H15.2783L17.2116 29.9781H21.8366L13.2697 10.2324H8.70038ZM13.4737 21.1312H8.52881L11.0433 15.1673L13.4737 21.1312Z"
                  fill="white"
                />
                <path
                  d="M29.4239 10.1309H24.6858V29.8498H38.8586V25.5484H29.4239V10.1309Z"
                  fill="white"
                />
                <path
                  d="M57.8608 12.7682C56.9709 11.8509 55.9115 11.1159 54.712 10.5839C53.5094 10.0514 52.214 9.78125 50.8618 9.78125C49.4916 9.78125 48.1914 10.0514 46.9971 10.5847C45.8061 11.1163 44.7514 11.8509 43.862 12.7682C42.9725 13.6854 42.2594 14.7784 41.7443 16.0149C41.2275 17.2547 40.9656 18.5997 40.9656 20.0127C40.9656 21.4266 41.2275 22.7674 41.7447 23.9982C42.2598 25.226 42.9721 26.3136 43.862 27.2309C44.7518 28.149 45.8065 28.8835 46.9971 29.4148C48.1902 29.9476 49.4904 30.2178 50.8618 30.2178C52.2148 30.2178 53.5102 29.9481 54.712 29.4152C55.9115 28.884 56.9705 28.149 57.8608 27.2309C58.7503 26.314 59.4666 25.2268 59.9905 23.9998C60.5164 22.767 60.7832 21.4253 60.7832 20.0127C60.7832 18.6001 60.5164 17.2551 59.9905 16.0128C59.467 14.7772 58.7503 13.6854 57.8608 12.7682ZM56.0451 20.0127C56.0451 20.7518 55.9083 21.455 55.6383 22.1022C55.3664 22.753 54.9908 23.3288 54.5209 23.813C54.0485 24.2997 53.493 24.6931 52.8691 24.981C52.2528 25.2668 51.5773 25.4116 50.8618 25.4116C50.1451 25.4116 49.4648 25.2664 48.8385 24.9798C48.207 24.6919 47.6478 24.2997 47.1763 23.813C46.7052 23.3275 46.3244 22.7509 46.0445 22.0984C45.7669 21.4534 45.6265 20.7514 45.6265 20.0127C45.6265 19.2744 45.7669 18.5725 46.0449 17.927C46.3248 17.2741 46.7064 16.6921 47.1787 16.1968C47.6486 15.7039 48.207 15.3076 48.8389 15.0189C49.4656 14.7326 50.1463 14.5874 50.8618 14.5874C51.5761 14.5874 52.2516 14.7322 52.8695 15.018C53.493 15.3063 54.0477 15.7031 54.5181 16.1968C54.9892 16.6909 55.366 17.2716 55.6379 17.9229C55.9083 18.5712 56.0451 19.274 56.0451 20.0127Z"
                  fill="white"
                />
                <path
                  d="M71.978 19.8779L82.0542 10.1797H75.8999L69.2452 16.6489V10.1797H64.3523V29.9786H69.2452V23.509L75.8999 29.9786H82.6045L71.978 19.8779Z"
                  fill="white"
                />
              </svg>
            </div>

            <span className="offcanvas__close-btn">
              <button onClick={() => setOffCanvas(false)}>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="20.2332"
                    cy="20"
                    r="19.5"
                    stroke="white"
                    strokeOpacity="0.4"
                  />
                  <path
                    d="M15.5066 25L24.5066 16"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5076 15.9688L24.5076 24.9688"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* <i className="fal fa-times" /> */}
              </button>
            </span>
          </div>

          <div className="divider"></div>

          <div className="menu-list offcanvas-content overflow-auto w-100">
            <Link className="menu-item" to="/">
              <p className="mb-0 menu-link">Home</p>
              <span className="tp-btn-sm text-primary" to="#">
                <span>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
            </Link>

            <Link to="/about" className="menu-item">
              <p className="mb-0 menu-link">About Us</p>
              <span className="tp-btn-sm text-primary" to="/about">
                <span>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
            </Link>

            <div>
              <div
                className="menu-item"
                onClick={() => {
                  setShowIndustries(!showIndustries);
                  setShowProducts(false);
                }}
              >
                <p className="mb-0 menu-link">Industries</p>
                <a className="tp-btn-sm text-primary">
                  <span>
                    <svg
                      width="36"
                      height="8"
                      viewBox="0 0 36 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                        fill="#fff"
                      />
                    </svg>
                    <svg
                      width="36"
                      height="8"
                      viewBox="0 0 36 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div
                className={
                  !showIndustries
                    ? "industries-mobile-nav d-block d-lg-none"
                    : "industries-mobile-nav industries-mobile-nav-expanded d-block d-lg-none"
                }
              >
                <div className="industries-submenu-container">
                  <Link to={"/industries/automotive"} className="industry-item">
                    <div className="logo">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#622466" />
                        <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                        <path d="M3.1123 30.9111V21.0815C6.64831 22.1946 9.93017 23.8682 12.8512 25.9965C9.93017 28.1252 6.64831 29.7981 3.1123 30.9111Z" fill="white" />
                        <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                        <path d="M37.1139 21.0815V30.9111C33.5783 29.7981 30.2961 28.1252 27.375 25.9965C30.2961 23.8685 33.5783 22.1946 37.1139 21.0815Z" fill="white" />
                        <path d="M20.1148 18.7842C17.9494 15.8489 16.2582 12.5514 15.1553 8.99977L15.1563 8.99837H25.0741L25.0751 8.99977C23.9718 12.5514 22.2806 15.8489 20.1148 18.7842Z" fill="white" />
                      </svg> */}
                      {/* <img src="/assets/img/industries/automobile1.jpg" /> */}
                    </div>

                    <p className="industry-name">Automotive</p>
                  </Link>

                  <Link
                    to={"/industries/agriculture"}
                    className="industry-item"
                  >
                    <div className="logo">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <rect width="40" height="40" rx="20" fill="#595DAA" />
                        <path
                          d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z"
                          fill="white"
                        />
                        <path
                          d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z"
                          fill="white"
                        />
                        <path
                          d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z"
                          fill="white"
                        />
                        <path
                          d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z"
                          fill="white"
                        />
                        <path
                          d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z"
                          fill="white"
                        />
                      </svg> */}

                      {/* <img src="/assets/img/industries/agriculture.jpg" /> */}

                    </div>

                    <p className="industry-name">Agriculture</p>
                  </Link>

                  <Link
                    to={"/industries/packaging-industry"}
                    className="industry-item"
                  >
                    <div className="logo">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <rect width="40" height="40" rx="20" fill="#C41E63" />
                        <path
                          d="M31.0683 14.9952C27.5167 16.0982 24.2192 17.7894 21.2839 19.9548C18.5302 17.9227 16.0951 15.4719 14.0713 12.6937C16.1992 9.77258 17.8729 6.49073 18.9859 2.95508H18.9919C21.441 8.30281 25.7456 12.5995 31.0683 14.9952Z"
                          fill="white"
                        />
                        <path
                          d="M9.15527 2.95508L18.9849 2.95508C17.8718 6.49109 16.1982 9.77294 14.0699 12.694C11.9412 9.77294 10.2683 6.49109 9.15527 2.95508Z"
                          fill="white"
                        />
                        <path
                          d="M21.2823 19.9551C24.2176 22.1208 27.5151 23.8121 31.0667 24.9154C25.744 27.3111 21.4394 31.6078 18.9903 36.9551H18.9843C17.8713 33.4198 16.1976 30.1373 14.0693 27.2166C16.0932 24.4384 18.5286 21.9879 21.2823 19.9551Z"
                          fill="white"
                        />
                        <path
                          d="M18.9849 36.9557H9.15527C10.2683 33.4201 11.9413 30.1379 14.0699 27.2168C16.1979 30.1379 17.8718 33.4201 18.9849 36.9557Z"
                          fill="white"
                        />
                        <path
                          d="M21.2822 19.9576C24.2175 17.7922 27.515 16.101 31.0666 14.998L31.068 14.9991V24.9169L31.0666 24.9179C27.515 23.8146 24.2175 22.1234 21.2822 19.9576Z"
                          fill="white"
                        />
                      </svg> */}

                      {/* <img src="/assets/img/industries/packaging.jpg" /> */}


                    </div>

                    <p className="industry-name">Packaging</p>
                  </Link>

                  <Link
                    to={"/industries/building-and-contruction-industry"}
                    className="industry-item"
                  >
                    <div className="logo">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <rect width="40" height="40" rx="20" fill="#F38336" />
                        <path
                          d="M15.1514 30.913C16.2544 27.3614 17.9456 24.064 20.111 21.1286C18.0789 18.3749 15.6281 15.9399 12.8499 13.916C9.92883 16.044 6.64698 17.7176 3.11133 18.8307V18.8366C8.45906 21.2857 12.7557 25.5903 15.1514 30.913Z"
                          fill="white"
                        />
                        <path
                          d="M3.11133 9V18.8296C6.64734 17.7166 9.92919 16.0429 12.8503 13.9146C9.92919 11.786 6.64734 10.1131 3.11133 9Z"
                          fill="white"
                        />
                        <path
                          d="M20.1113 21.127C22.2771 24.0624 23.9683 27.3598 25.0716 30.9114C27.4674 25.5887 31.764 21.2841 37.1114 18.835V18.8291C33.5761 17.716 30.2935 16.0424 27.3728 13.9141C24.5946 15.9379 22.1441 18.3733 20.1113 21.127Z"
                          fill="white"
                        />
                        <path
                          d="M37.112 18.8296V9C33.5763 10.1131 30.2941 11.786 27.373 13.9146C30.2941 16.0426 33.5763 17.7166 37.112 18.8296Z"
                          fill="white"
                        />
                        <path
                          d="M20.1139 21.127C17.9485 24.0623 16.2573 27.3597 15.1543 30.9114L15.1554 30.9128H25.0731L25.0742 30.9114C23.9709 27.3597 22.2796 24.0623 20.1139 21.127Z"
                          fill="white"
                        />
                      </svg> */}

                      {/* <img src="/assets/img/industries/packaging.jpg" /> */}

                    </div>

                    <p className="industry-name">Building & Construction</p>
                  </Link>

                  <Link
                    to={"/industries/consumer-durables-industry"}
                    className="industry-item"
                  >
                    <div className="logo">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <rect width="40" height="40" rx="20" fill="#115E66" />
                        <path
                          d="M15.1514 8.99907C16.2544 12.5507 17.9456 15.8481 20.111 18.7835C18.0789 21.5372 15.6281 23.9723 12.8499 25.9961C9.92883 23.8681 6.64698 22.1945 3.11133 21.0815V21.0755C8.45906 18.6264 12.7557 14.3218 15.1514 8.99907Z"
                          fill="white"
                        />
                        <path
                          d="M3.11133 30.9121V21.0825C6.64734 22.1955 9.92919 23.8692 12.8503 25.9975C9.92919 28.1261 6.64734 29.7991 3.11133 30.9121Z"
                          fill="white"
                        />
                        <path
                          d="M20.1113 18.7851C22.2771 15.8497 23.9683 12.5523 25.0716 9.00067C27.4674 14.3234 31.764 18.628 37.1114 21.0771V21.0831C33.5761 22.1961 30.2935 23.8697 27.3728 25.998C24.5946 23.9742 22.1441 21.5388 20.1113 18.7851Z"
                          fill="white"
                        />
                        <path
                          d="M37.112 21.0825V30.9121C33.5763 29.7991 30.2941 28.1261 27.373 25.9975C30.2941 23.8695 33.5763 22.1955 37.112 21.0825Z"
                          fill="white"
                        />
                        <path
                          d="M20.1139 18.7852C17.9485 15.8498 16.2573 12.5524 15.1543 9.00075L15.1554 8.99935H25.0731L25.0742 9.00075C23.9709 12.5524 22.2796 15.8498 20.1139 18.7852Z"
                          fill="white"
                        />
                      </svg> */}
                    </div>

                    <p className="industry-name">Consumer Durables</p>
                  </Link>

                  <Link
                    to={"/industries/textile-and-fibers-industry"}
                    className="industry-item"
                  >
                    <div className="logo">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <rect width="40" height="40" rx="20" fill="#8A8B35" />
                        <path
                          d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z"
                          fill="white"
                        />
                        <path
                          d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z"
                          fill="white"
                        />
                        <path
                          d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z"
                          fill="white"
                        />
                        <path
                          d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z"
                          fill="white"
                        />
                        <path
                          d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z"
                          fill="white"
                        />
                      </svg> */}
                    </div>

                    <p className="industry-name">Textiles & Fibre</p>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div
                className="menu-item"
                onClick={() => {
                  setShowIndustries(false);
                  setShowProducts(!showProducts);
                }}
              >
                <p className="mb-0 menu-link">Products</p>
                <a className="tp-btn-sm text-primary">
                  <span>
                    <svg
                      width="36"
                      height="8"
                      viewBox="0 0 36 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                        fill="#fff"
                      />
                    </svg>
                    <svg
                      width="36"
                      height="8"
                      viewBox="0 0 36 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </a>
              </div>

              <div
                className={
                  !showProducts
                    ? "products-mobile-nav d-block d-lg-none"
                    : "products-mobile-nav products-mobile-nav-expanded d-block d-lg-none"
                }
              >
                <div className="products-submenu">
                  <div className="products-submenu-container">
                    <Link
                      to={"/additive-masterbatches"}
                      className="products-item "
                    >
                      <div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">AM</p>
                      </div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Additive Masterbatches
                        </p>
                      </div>
                    </Link>

                    <Link
                      to={"/white-masterbatches"}
                      className="products-item "
                    >
                      <div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">WM</p>
                      </div>
                      <div className="col-9">
                        <p className="products-desc mb-0">White Masterbatches</p>
                      </div>
                    </Link>

                    <Link
                      to={"/black-masterbatches"}
                      className="products-item "
                    ><div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">BM</p>
                      </div>
                      <div className="col-9">
                        <p className="products-desc mb-0">Black Masterbatches</p> </div>
                    </Link>

                    <Link
                      to={"/mineral-masterbatches"}
                      className="products-item "
                    >
                      <div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">MB</p>
                      </div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Mineral Masterbatches
                        </p> </div>
                    </Link>

                    <Link to={"/uv-masterbatches"} className="products-item "><div className="col-3" style={{width : "50px"}}>
                      <p className="products-name mb-0">UV</p>
                    </div>
                      <div className="col-9">
                        <p className="products-desc mb-0">UV Masterbatches</p>
                      </div>
                    </Link>

                    <Link
                      to={"/agriculture-masterbatches"}
                      className="products-item "
                    ><div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">AH</p></div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Masterbatches for Agriculture & Horticulture
                        </p> </div>
                    </Link>

                    

                    <Link
                      to={"/recycling-masterbatches"}
                      className="products-item "
                    ><div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">Re</p></div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Masterbatches for Recycling
                        </p> </div>
                    </Link>

                    <Link
                      to={"/fibre-masterbatches"}
                      className="products-item "
                    ><div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">FB</p></div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Masterbatches for Fibers & Nonwovens
                        </p> </div>
                    </Link>
                    <Link
                      to={"/color-masterbatches"}
                      className="products-item "
                    ><div className="col-3" style={{width : "50px"}}>
                        <p className="products-name mb-0">CM</p></div>
                      <div className="col-9">
                        <p className="products-desc mb-0">
                          Color Masterbatches & Single Pigment Concentrates
                        </p> </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/sustainability" className="menu-item">
              <p className="mb-0 menu-link">Sustainability</p>
              <span className="tp-btn-sm text-primary" to="#">
                <span>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
            </Link>

            <Link to="/regulatory" className="menu-item">
              <p className="mb-0 menu-link">Regulatory</p>
              <span className="tp-btn-sm text-primary" to="#">
                <span>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
            </Link>

            <div className="menu-list2">
              <Link to="/careers" className="menu-item">
                <p className="mb-0 menu-link">Careers</p>
              </Link>

              {/* <Link to="#" className="menu-item">
                <p className="mb-0 menu-link">Exhibition Calendar</p>
              </Link> */}

              <Link to="/contact-us" className="menu-item">
                <p className="mb-0 menu-link">Contact Us</p>
              </Link>
            </div>

            <div className="social-links">
              <div className="social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                    fill="#9E9FA3"
                  />
                </svg>
              </div>

              <div className="social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17.0169 21.3333L4 4H8.31642L21.3333 21.3333H17.0169Z"
                    fill="#9E9FA3"
                    stroke="#9E9FA3"
                    strokeWidth="0.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.332 4L13.332 12"
                    stroke="#9E9FA3"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.66602 21.3333L11.9993 14"
                    stroke="#9E9FA3"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5.44405 9.15601H1.63844V21.334H5.44405V9.15601ZM5.78656 3.54124C5.78857 3.25339 5.73385 2.96795 5.62554 2.70122C5.51724 2.43451 5.35745 2.19173 5.1553 1.98678C4.95317 1.78181 4.71264 1.61866 4.44746 1.50666C4.18227 1.39466 3.89761 1.33598 3.60975 1.33398H3.54124C2.95585 1.33398 2.39442 1.56654 1.98047 1.98048C1.56654 2.39442 1.33398 2.95585 1.33398 3.54124C1.33398 4.12665 1.56654 4.68808 1.98047 5.10203C2.39442 5.51596 2.95585 5.74852 3.54124 5.74852C3.82913 5.7556 4.11559 5.70588 4.38425 5.60221C4.65292 5.49856 4.89853 5.34297 5.10704 5.14435C5.31555 4.94574 5.48289 4.70797 5.59949 4.44465C5.71608 4.18134 5.77965 3.89764 5.78656 3.60976V3.54124ZM21.334 13.9359C21.334 10.2749 19.0049 8.85157 16.6912 8.85157C15.9336 8.81363 15.1792 8.97498 14.5034 9.31955C13.8278 9.66405 13.254 10.1798 12.8399 10.8152H12.7332V9.15601H9.156V21.334H12.9616V14.8568C12.9066 14.1935 13.1156 13.5352 13.5431 13.025C13.9706 12.5148 14.5822 12.1939 15.245 12.1321H15.3896C16.5998 12.1321 17.4979 12.8931 17.4979 14.8111V21.334H21.3036L21.334 13.9359Z"
                    fill="#9E9FA3"
                  />
                </svg>
              </div>

              <div className="social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22.125 7.89873C22.125 7.07762 21.7988 6.29014 21.2182 5.70953C20.6376 5.12892 19.8501 4.80273 19.029 4.80273H4.971C4.56443 4.80273 4.16184 4.88281 3.78621 5.0384C3.41059 5.19399 3.06929 5.42204 2.7818 5.70953C2.20118 6.29014 1.875 7.07762 1.875 7.89873V16.1067C1.875 16.9278 2.20118 17.7153 2.7818 18.2959C3.06929 18.5834 3.41059 18.8115 3.78621 18.9671C4.16184 19.1227 4.56443 19.2027 4.971 19.2027H19.029C19.8501 19.2027 20.6376 18.8765 21.2182 18.2959C21.7988 17.7153 22.125 16.9278 22.125 16.1067V7.89873ZM9.75 15.5487V7.75473L15.843 11.6517L9.75 15.5487Z"
                    fill="#9E9FA3"
                  />
                </svg>
              </div>
            </div>

            <div className="menu-list2 mt-5 pt-5">
              <div className="menu-item">
                <p className="mb-0 menu-link">Other Sites:</p>
              </div>
              <div className="row w-100">
                <div className="col-md-6">
                  <img src="/assets/img/alok_logo.png" />
                </div>

                <div className="col-md-6" style={{ paddingTop: "12px" }}>
                  <img src="/assets/img/attd-logo.png" />
                </div>
              </div>


            </div>



            <div className="menu-bg">
              <svg width="122" height="248" viewBox="0 0 122 248" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2">
                  <path d="M2.22484 88.3464C39.4397 70.4814 68.772 39.939 85.9772 2.3003C94.6602 27.6236 106.925 50.139 122.012 71.0102C107.593 90.7589 89.8099 108.876 70.2145 123.465C49.7517 108.394 26.8563 96.4579 2.22484 88.3464Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M174.922 122.495C155.298 108.024 137.055 91.0538 122.639 71.4965C137.718 50.8092 151.169 27.2999 159.188 2.35254C176.393 39.6603 205.714 69.9373 242.892 87.6535C218.272 95.7039 195.378 107.546 174.922 122.495Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M158.688 0.84241V0.869648C150.83 26.4532 137.923 49.3051 122.566 70.5492C107.409 49.5493 94.6229 26.0803 86.7384 0.84241L158.688 0.84241Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M70.0821 125.067C89.6367 139.588 106.916 156.995 121.296 176.649C106.256 197.429 94.4111 220.674 86.5014 245.673C69.2999 208.211 39.3607 177.821 2.22931 160.043C26.8096 151.964 49.6595 140.071 70.0821 125.067Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M0.84241 88.7024C26.0965 96.814 49.5734 108.824 70.5527 124.027C49.5733 139.227 26.0964 151.242 0.84241 159.354L0.84241 88.7024Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M243.883 159.298C206.06 177.46 175.575 209.38 158.07 247.626C150.017 222.121 137.955 198.405 122.636 177.205C137.29 157.13 154.882 138.434 174.781 123.616C195.576 138.927 218.851 151.06 243.883 159.298Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                  <path d="M156.447 247.209H86.7403C94.6039 221.963 106.473 198.486 121.591 177.504C136.711 198.486 148.58 221.963 156.447 247.209Z" stroke="#9E9FA3" strokeWidth="1.68482" />
                </g>
              </svg>

            </div>


            {/* <div className="offcanva-btn">
            <Link
              to="/contact-us"
              className="tp-slider-btn mt-40 d-inline-block"
              tabIndex="-1"
            >
              <span>
                <svg
                  width="53"
                  height="8"
                  viewBox="0 0 53 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <svg
                  width="53"
                  height="8"
                  viewBox="0 0 53 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              Get in touch
            </Link>
          </div> */}
          </div>

          {/* <div className="tp-mobile-menu mean-container d-block d-xl-none">
          <div className="mean-bar">
            <MobileMenus />
          </div>
        </div> */}
        </div>
      </div>
      <div
        onClick={() => setOffCanvas(false)}
        className={`body-overlay ${isOffCanvas ? "opened" : ""}`}
      ></div>
    </>
  );
};

export default OffCanvas;

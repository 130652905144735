import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';

const Achievements = () => {

    const achievements = [
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "National Award for Innovation by the Government of India in 2014",
        //     id: 1
        // },
        {
            image: "/assets/img/achievements/1.jpeg",
            // title: "Transform BFSI",
            description: "National Award for Innovation by the Government of India in 2015",
            id: 1
        },
        {
            image: "/assets/img/achievements/2.jpeg",
            // title: "Accelerate apps",
            description: "Golden Peacock Eco-Innovation Award 2019",
            id: 2
        },
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "Golden Peacock Product Innovation Award 2020",
        //     id: 4
        // },
        {
            image: "/assets/img/achievements/3.png",
            // title: "Transform BFSI",
            description: "FICCI Chemicals & Petrochemicals Award 2019",
            id: 3
        },
        {
            image: "/assets/img/achievements/5.png",
            // title: "Transform BFSI",
            description: "Innovations in Energy efficiency from CII 2021",
            id: 5
        },
        {
            image: "/assets/img/achievements/4.png",
            // title: "Accelerate apps",
            description: "Noteworthy Water Efficient Unit Award from CII 2020",
            id: 4
        },
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "Noteworthy Water Efficient Unit Award from CII 2021",
        //     id: 7
        // },
        {
            image: "/assets/img/achievements/6.png",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 6
        },
        {
            image: "/assets/img/achievements/7.png",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 7
        },
        {
            image: "/assets/img/achievements/8.png",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 8
        },
        {
            image: "/assets/img/achievements/9.png",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 9
        },
    ]

    const mobileAchievements = [
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "National Award for Innovation by the Government of India in 2014",
        //     id: 1
        // },
        {
            image: "/assets/img/achievements/mobile1.jpeg",
            // title: "Transform BFSI",
            description: "National Award for Innovation by the Government of India in 2015",
            id: 1
        },
        {
            image: "/assets/img/achievements/mobile2.jpeg",
            // title: "Accelerate apps",
            description: "Golden Peacock Eco-Innovation Award 2019",
            id: 2
        },
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "Golden Peacock Product Innovation Award 2020",
        //     id: 4
        // },
        {
            image: "/assets/img/achievements/mobile3.jpeg",
            // title: "Transform BFSI",
            description: "FICCI Chemicals & Petrochemicals Award 2019",
            id: 3
        },
        {
            image: "/assets/img/achievements/mobile5.jpeg",
            // title: "Transform BFSI",
            description: "Innovations in Energy efficiency from CII 2021",
            id: 5
        },
        {
            image: "/assets/img/achievements/mobile4.jpeg",
            // title: "Accelerate apps",
            description: "Noteworthy Water Efficient Unit Award from CII 2020",
            id: 4
        },
        // {
        //     image: "/assets/img/achievements/achievement.png",
        //     // title: "Transform BFSI",
        //     description: "Noteworthy Water Efficient Unit Award from CII 2021",
        //     id: 7
        // },
        {
            image: "/assets/img/achievements/mobile6.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 6
        },
        {
            image: "/assets/img/achievements/mobile7.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 7
        },
        {
            image: "/assets/img/achievements/mobile8.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 8
        },
        {
            image: "/assets/img/achievements/mobile9.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 9
        },
        {
            image: "/assets/img/achievements/mobile10.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 10
        },
        {
            image: "/assets/img/achievements/mobile11.jpeg",
            // title: "Accelerate apps",
            description: "Efficiency in Water Usage in Petrochemicals- FICCI 2021",
            id: 11
        }
    ]

    const settings = {
        infinite: true,
        // centerMode: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 2,
        // customPaging: "50px",
        // slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        // fade: true,
        pauseOnHover: true,
        className: "slider variable-width",
        // dots: true,
        infinite: true,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        // adaptiveHeight: true,
        arrows: false,
        pauseOnHover: true, // Pause carousel on hover
        afterChange: (current) => {
            setActiveSlide(current)
        },
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    // slidesToShow: 1.2,
                    // centerMode: true,
                    // dots: true
                },
            },
        ],
    };


    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <>
            {/* <!-- cta area start --> */}
            <div className="tp-cta-area achievement-section pt-60 pb-80 mobile-pb-20 " id="achievementSection">
                <div className="container">
                    <div className="tp-cta-wrapper mb-10 mb-lg-100">
                        <div className="row align-items-center justify-content-evenly">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center mb-40">
                                <div className="tp-section-wrapper">
                                    <p className="tp-section-subtitle mb-20">
                                        ALOK at forefront
                                    </p>
                                    <h2 className="tp-section-title">
                                        Accelerating change through collaboration and partnerships
                                    </h2>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="image-container positive-relative" style={{ margin: "auto", height:"750px" }}>


                    <Swiper
                        ref={sliderRef}
                        // effect={'cards'}
                        grabCursor={true}
                        // modules={[EffectCards]}
                        className="mySwiper"
                      // slidesPerView={1}
                      // spaceBetween={50}
                            autoHeight={true}
                      loop={true}
                      autoplay
                      // onSlideChange={() => console.log('slide change')}
                      // onSwiper={(swiper) => console.log(swiper)}
                      >
                       
                        {(isMobile ? mobileAchievements : achievements)?.map((achievement) => (
                                <SwiperSlide>
                                <img src={achievement.image} alt="" height={"750px"} className="image" />
                              </SwiperSlide>
                            ))}

                      </Swiper>
                        
                        {/* <img src={achievements[activeSlide].image} className="image" /> */}

                        <div className=" row" >
                            <div className="col-lg-6 ">
                                <div className="section-1 d-none d-lg-block">
                                    {/* <p className="hero-text">
                                        A multi-award & accolades accredited company that's reliable & responsible
                                    </p> */}
                                    {/* <div className="offcanva-btn" style={{marginTop : "25px"}}>
                                        <Link
                                            to="/contact-us"
                                            className="tp-slider-btn d-inline-block"
                                            tabIndex="-1"
                                        >
                                            <span>
                                                <svg
                                                    width="53"
                                                    height="8"
                                                    viewBox="0 0 53 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                                <svg
                                                    width="53"
                                                    height="8"
                                                    viewBox="0 0 53 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            READ MORE IN DETAIL
                                        </Link>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-lg-6 p-0">

                                {/* <div className="section-2">
                                    <Slider  {...settings} ref={sliderRef} className="w-100 ">
                                        {achievements.map((item, index) => (
                                            <div key={index} className="px-2 ">
                                                <div className={index === activeSlide ? "achievement achievement-active" : "achievement"}>
                                                    <p className="title mb-0">{item.title}</p>
                                                    <p className="description mb-0">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div> */}
                            </div>
                        </div>


                        <div className="d-flex justify-content-between align-items-center
                         gap-20 position-absolute top-0 h-100 w-100 px-3" style={{zIndex: 1}}>

                            <button
                                onClick={() => sliderRef.current.swiper.slidePrev()}
                                type="button"
                            >
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="78" height="55" viewBox="0 0 78 55" fill="none">
        <circle cx="27.5" cy="27.5" r="27" transform="matrix(-1 0 0 1 55 0)" fill="" stroke="#622466" />
        <path d="M25.6464 27.3536C25.4512 27.1583 25.4512 26.8417 25.6464 26.6464L28.8284 23.4645C29.0237 23.2692 29.3403 23.2692 29.5355 23.4645C29.7308 23.6597 29.7308 23.9763 29.5355 24.1716L26.7071 27L29.5355 29.8284C29.7308 30.0237 29.7308 30.3403 29.5355 30.5355C29.3403 30.7308 29.0237 30.7308 28.8284 30.5355L25.6464 27.3536ZM78 27.5L26 27.5L26 26.5L78 26.5L78 27.5Z" fill="#622466" />
    </svg>   */}

                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.98">
                                        <circle cx="27" cy="27" r="26" stroke={"#622466"} stroke-width="2" />
                                        <path d="M31.5 36L22.5 27L31.5 18" stroke={"#622466"} stroke-width="2" stroke-linejoin="round" />
                                    </g>
                                </svg>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
        <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
    </svg> */}
                            </button>

                            <button
                                onClick={() => sliderRef.current.swiper.slideNext()}
                                type="button"
                            >
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="78" height="55" viewBox="0 0 78 55" fill="none">
        <circle cx="50.5" cy="27.5" r="27" fill="" stroke="#622466" />
        <path d="M52.3536 27.3536C52.5488 27.1583 52.5488 26.8417 52.3536 26.6464L49.1716 23.4645C48.9763 23.2692 48.6597 23.2692 48.4645 23.4645C48.2692 23.6597 48.2692 23.9763 48.4645 24.1716L51.2929 27L48.4645 29.8284C48.2692 30.0237 48.2692 30.3403 48.4645 30.5355C48.6597 30.7308 48.9763 30.7308 49.1716 30.5355L52.3536 27.3536ZM-3.11023e-08 27.5L52 27.5L52 26.5L3.11023e-08 26.5L-3.11023e-08 27.5Z" fill="#622466" />
    </svg> */}

                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.98" cx="27" cy="27" r="26" stroke={"#622466"} stroke-width="2" />
                                    <path d="M22.5 36L31.5 27L22.5 18" stroke={"#622466"} stroke-width="2" stroke-linejoin="round" />
                                </svg>
                            </button>

                        </div>
                    </div>



                </div>
            </div>
            {/* <!-- cta end end --> */}
        </>
    );
};

export default Achievements;

import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function UV() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/uv.jpg'} title={"Our Product"} subtext={"UV Masterbatches Superior Protection, Enhanced Durability"} textAvatar="UV" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of UV Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>As India's largest manufacturer of UV Masterbatches, ALOK provides unparalleled expertise in light stabilization of polymers. Our UV Masterbatches are designed to protect plastics from UV degradation, enhancing longevity and maintaining performance in harsh environmental conditions. With a robust portfolio ranging from basic to advanced applications, ALOK UV Masterbatches meet diverse industry needs, ensuring products remain durable and color-stable under sunlight exposure.</p>
              </div>

              <div className='title-container'>
                <h3 className='title'>UV Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches offer robust protection against UV degradation, ensuring longevity and consistent performance in harsh environmental conditions. Our products meet diverse industry needs with advanced UV stabilization.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>UvNox FGL</td>
                          <td>Food contact safe, suitable for PE & PP applications, ensuring safety and UV protection.</td>
                          <td>Used in food packaging and consumer goods.</td>
                        </tr>
                        <tr>
                          <td>UvNox FGL (WT)</td>
                          <td>Economical, mineral-filled, food-grade white UV stabilizer for woven sacks and FIBC.</td>
                          <td>Ideal for economical packaging solutions.</td>
                        </tr>
                        <tr>
                          <td>UvNox 20</td>
                          <td>Non-food grade, mineral-filled UV stabilizer for general applications in PP materials.</td>
                          <td>Suitable for general outdoor PP applications.</td>
                        </tr>
                        <tr>
                          <td>UvNox BT</td>
                          <td>Blue tone, economical, mineral-filled UV stabilizer for aesthetic and functional enhancements in FIBC.</td>
                          <td>Enhances appearance and UV protection in FIBC.</td>
                        </tr>
                        <tr>
                          <td>UvNox DLR</td>
                          <td>Custom UV stabilizer for POM applications in the automotive industry.</td>
                          <td>Used in automotive components.</td>
                        </tr>
                        <tr>
                          <td>UvNox ABS</td>
                          <td>Specialized UV stabilizer for ABS applications, offering enhanced protection and longevity.</td>
                          <td>Ideal for durable consumer electronics.</td>
                        </tr>
                        <tr>
                          <td>UvNox Shelf</td>
                          <td>Designed for content protection in PET clear FMCG packaging, maintaining product integrity.</td>
                          <td>Used in FMCG packaging to prevent UV damage.</td>
                        </tr>
                        <tr>
                          <td>UvNox Tank</td>
                          <td>Food contact safe UV stabilizer, designed for outdoor water tanks to resist UV degradation.</td>
                          <td>Used in outdoor water tanks and containers.</td>
                        </tr>
                        <tr>
                          <td>UvNox Net</td>
                          <td>Specialized for PE shade net applications, providing essential UV protection.</td>
                          <td>Ideal for agricultural and protective netting.</td>
                        </tr>
                        <tr>
                          <td>UvNox Mulch</td>
                          <td>Pesticide-resistant UV Masterbatch for mulch film applications, enhancing durability and functionality.</td>
                          <td>Used in agricultural mulch films.</td>
                        </tr>
                        <tr>
                          <td>UvNox MF</td>
                          <td>UV Masterbatch designed for PP monofilament yarn, ensuring UV stability and product durability.</td>
                          <td>Suitable for textiles and industrial fabrics.</td>
                        </tr>
                        <tr>
                          <td>UvNox NOW</td>
                          <td>Pesticide-resistant UV Masterbatch tailored for shade net applications in challenging agricultural settings.</td>
                          <td>Used in durable agricultural shade nets.</td>
                        </tr>
                       

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-card-btn'>
              <ProductContact />
            </div>
          </div>
          <div className='col-md-4 mobile-padding'>
            <ProductsSidebar />
          </div>
        </div>

        {/* <OtherProducts/> */}
      </div>
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default UV
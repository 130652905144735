import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const TopCtaArea = () => {

  const [industries, setIndustries] = useState([
    {
      image: "/assets/img/industries/agriculture.jpg",
      title: "Agriculture",
      description: "Innovating with advanced Masterbatches that enhance the performance and durability of agricultural films",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#595DAA"/>
      <path d="M9.15532 24.9159C12.7069 23.813 16.0044 22.1217 18.9397 19.9564C21.6934 21.9885 24.1285 24.4393 26.1523 27.2175C24.0244 30.1385 22.3508 33.4204 21.2377 36.9561H21.2317C18.7827 31.6083 14.478 27.3117 9.15532 24.9159Z" fill="white"/>
      <path d="M31.0684 36.9561H21.2387C22.3518 33.42 24.0254 30.1382 26.1537 27.2171C28.2824 30.1382 29.9553 33.42 31.0684 36.9561Z" fill="white"/>
      <path d="M18.9413 19.9561C16.006 17.7903 12.7085 16.0991 9.15692 14.9958C14.4796 12.6 18.7843 8.30336 21.2333 2.95599H21.2393C22.3524 6.49128 24.026 9.77386 26.1543 12.6946C24.1305 15.4728 21.695 17.9232 18.9413 19.9561Z" fill="white"/>
      <path d="M21.2387 2.95539H31.0684C29.9553 6.49104 28.2824 9.77326 26.1537 12.6943C24.0258 9.77326 22.3518 6.49104 21.2387 2.95539Z" fill="white"/>
      <path d="M18.9414 19.9535C16.0061 22.1189 12.7086 23.8101 9.157 24.9131L9.1556 24.912V14.9943L9.157 14.9932C12.7086 16.0965 16.0061 17.7878 18.9414 19.9535Z" fill="white"/>
    </svg>`,
      link: "/industries/agriculture",
      class: "logo rotate180",
      id: 1
    },
    {
      image: "/assets/img/industries/automobile1.jpg",
      title: "Automotive",
      description: "Driving the future with high-performance polymer compounds and functional Masterbatches for automotive manufacturing.",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#622466"/>
      <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white"/>
      <path d="M3.1123 30.9111V21.0815C6.64831 22.1946 9.93017 23.8682 12.8512 25.9965C9.93017 28.1252 6.64831 29.7981 3.1123 30.9111Z" fill="white"/>
      <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white"/>
      <path d="M37.1139 21.0815V30.9111C33.5783 29.7981 30.2961 28.1252 27.375 25.9965C30.2961 23.8685 33.5783 22.1946 37.1139 21.0815Z" fill="white"/>
      <path d="M20.1148 18.7842C17.9494 15.8489 16.2582 12.5514 15.1553 8.99977L15.1563 8.99837H25.0741L25.0751 8.99977C23.9718 12.5514 22.2806 15.8489 20.1148 18.7842Z" fill="white"/>
    </svg>`,
      link: "/industries/automotive",
      class: "logo rotate90",
      id: 2
    },
    {
      image: "/assets/img/industries/building.jpg",
      title: "Building and Construction",
      description: "Building stronger and more sustainable structures with specialized Masterbatches for construction applications",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#F38336"/>
      <path d="M15.1514 30.9121C16.2544 27.3604 17.9456 24.063 20.111 21.1277C18.0789 18.3739 15.6281 15.9389 12.8499 13.915C9.92883 16.043 6.64698 17.7166 3.11133 18.8297V18.8356C8.45906 21.2847 12.7557 25.5894 15.1514 30.9121Z" fill="white"/>
      <path d="M3.11133 8.99902V18.8287C6.64734 17.7156 9.92919 16.042 12.8503 13.9137C9.92919 11.785 6.64734 10.1121 3.11133 8.99902Z" fill="white"/>
      <path d="M20.1113 21.1261C22.2771 24.0614 23.9683 27.3588 25.0716 30.9105C27.4674 25.5878 31.764 21.2831 37.1114 18.834V18.8281C33.5761 17.715 30.2935 16.0414 27.3728 13.9131C24.5946 15.9369 22.1441 18.3723 20.1113 21.1261Z" fill="white"/>
      <path d="M37.112 18.8287V8.99902C33.5763 10.1121 30.2941 11.785 27.373 13.9137C30.2941 16.0416 33.5763 17.7156 37.112 18.8287Z" fill="white"/>
      <path d="M20.1139 21.126C17.9485 24.0613 16.2573 27.3588 15.1543 30.9104L15.1554 30.9118H25.0731L25.0742 30.9104C23.9709 27.3588 22.2796 24.0613 20.1139 21.126Z" fill="white"/>
    </svg>`,
      link: "/industries/building-and-contruction-industry",
      class: "logo rotate-90",
      id: 3
    },

    {
      image: "/assets/img/industries/consumer.jpg",
      title: "Consumer Durables",
      description: "Enhancing consumer products with reliable and safe Masterbatches for toys, sports equipment, and household appliances.",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115E66"/>
      <path d="M15.1514 8.99907C16.2544 12.5507 17.9456 15.8481 20.111 18.7835C18.0789 21.5372 15.6281 23.9723 12.8499 25.9961C9.92883 23.8681 6.64698 22.1945 3.11133 21.0815V21.0755C8.45906 18.6264 12.7557 14.3218 15.1514 8.99907Z" fill="white"/>
      <path d="M3.11133 30.9121V21.0825C6.64734 22.1955 9.92919 23.8692 12.8503 25.9975C9.92919 28.1261 6.64734 29.7991 3.11133 30.9121Z" fill="white"/>
      <path d="M20.1113 18.7851C22.2771 15.8497 23.9683 12.5523 25.0716 9.00067C27.4674 14.3234 31.764 18.628 37.1114 21.0771V21.0831C33.5761 22.1961 30.2935 23.8697 27.3728 25.998C24.5946 23.9742 22.1441 21.5388 20.1113 18.7851Z" fill="white"/>
      <path d="M37.112 21.0825V30.9121C33.5763 29.7991 30.2941 28.1261 27.373 25.9975C30.2941 23.8695 33.5763 22.1955 37.112 21.0825Z" fill="white"/>
      <path d="M20.1139 18.7852C17.9485 15.8498 16.2573 12.5524 15.1543 9.00075L15.1554 8.99935H25.0731L25.0742 9.00075C23.9709 12.5524 22.2796 15.8498 20.1139 18.7852Z" fill="white"/>
    </svg>`,
      link: "/industries/consumer-durables-industry",
      class: "logo rotate90",
      id: 4
    },
    {
      image: "/assets/img/industries/packaging.jpg",
      title: "Packaging",
      description: "Revolutionizing packaging solutions with high-quality Masterbatches that improve aesthetics and functionality.",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#C41E63"/>
      <path d="M31.0683 14.9952C27.5167 16.0982 24.2192 17.7894 21.2839 19.9548C18.5302 17.9227 16.0951 15.4719 14.0713 12.6937C16.1992 9.77258 17.8729 6.49073 18.9859 2.95508H18.9919C21.441 8.30281 25.7456 12.5995 31.0683 14.9952Z" fill="white"/>
      <path d="M9.15527 2.95508L18.9849 2.95508C17.8718 6.49109 16.1982 9.77294 14.0699 12.694C11.9412 9.77294 10.2683 6.49109 9.15527 2.95508Z" fill="white"/>
      <path d="M21.2823 19.9551C24.2176 22.1208 27.5151 23.8121 31.0667 24.9154C25.744 27.3111 21.4394 31.6078 18.9903 36.9551H18.9843C17.8713 33.4198 16.1976 30.1373 14.0693 27.2166C16.0932 24.4384 18.5286 21.9879 21.2823 19.9551Z" fill="white"/>
      <path d="M18.9849 36.9557H9.15527C10.2683 33.4201 11.9413 30.1379 14.0699 27.2168C16.1979 30.1379 17.8718 33.4201 18.9849 36.9557Z" fill="white"/>
      <path d="M21.2822 19.9576C24.2175 17.7922 27.515 16.101 31.0666 14.998L31.068 14.9991V24.9169L31.0666 24.9179C27.515 23.8146 24.2175 22.1234 21.2822 19.9576Z" fill="white"/>
    </svg>`,
      link: "/industries/packaging-industry",
      class: "logo",
      id: 5
    },
    {
      image: "/assets/img/industries/textile.jpg",
      title: "Textile and Fibers",
      description: " Pioneering textile innovations with Masterbatches that transform fiber properties for enhanced performance.",
      logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#8A8B35"/>
      <path d="M9.15532 24.9159C12.7069 23.813 16.0044 22.1217 18.9397 19.9564C21.6934 21.9885 24.1285 24.4393 26.1523 27.2175C24.0244 30.1385 22.3508 33.4204 21.2377 36.9561H21.2317C18.7827 31.6083 14.478 27.3117 9.15532 24.9159Z" fill="white"/>
      <path d="M31.0684 36.9561H21.2387C22.3518 33.42 24.0254 30.1382 26.1537 27.2171C28.2824 30.1382 29.9553 33.42 31.0684 36.9561Z" fill="white"/>
      <path d="M18.9413 19.9561C16.006 17.7903 12.7085 16.0991 9.15692 14.9958C14.4796 12.6 18.7843 8.30336 21.2333 2.95599H21.2393C22.3524 6.49128 24.026 9.77386 26.1543 12.6946C24.1305 15.4728 21.695 17.9232 18.9413 19.9561Z" fill="white"/>
      <path d="M21.2387 2.95539H31.0684C29.9553 6.49104 28.2824 9.77326 26.1537 12.6943C24.0258 9.77326 22.3518 6.49104 21.2387 2.95539Z" fill="white"/>
      <path d="M18.9414 19.9535C16.0061 22.1189 12.7086 23.8101 9.157 24.9131L9.1556 24.912V14.9943L9.157 14.9932C12.7086 16.0965 16.0061 17.7878 18.9414 19.9535Z" fill="white"/>
    </svg>`,
      link: "/industries/textile-and-fibers-industry",
      class: "logo rotate180",
      id: 6
    },
  ])

  const settings = {
    infinite: true,
    // lazyLoad: true,
    // centerMode: true,
    speed: 500,
    // slidesToShow: 1.1,
    // customPaging: "10px",
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,


    className: "slider variable-width",
    // dots: true,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,


    // fade: true,
    arrows: false,
    pauseOnHover: false, // Pause carousel on hover
    afterChange: (current) => {
      setActiveSlide(current)
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
          className: "slider variable-width",
          // dots: true,
          infinite: true,
          // centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };


  const sliderRef = useRef(null);
  const mobileSliderRef = useRef(null);

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area mobile-bottom industries-section" id="industriesSection">
        <div className="container-fluid overflow-hidden">
          <div className="tp-cta-wrapper container">
            <div className="row align-items-center justify-content-evenly">
              <div className="col-lg-12 p-0">
                <div className="tp-section-wrapper">
                  <p className="tp-section-subtitle mb-20">
                    Our Industries
                  </p>
                  <h2 className="tp-section-title">
                    From strategy to delivery, we are here to make sure your business endeavor succeeds. Trust our experts.
                  </h2>
                </div>
              </div>
              {/* <div className="col-lg-3 d-flex justify-content-lg-end">
                <Link to="/team">
                  <div className="tp-theme-btn d-flex align-items-center">
                    <div className="tp-btn-inner">
                      <p className="mb-0">Our Team</p>
                      <b>Meet our Team</b>
                      <span className="d-inline-block">
                        <svg
                          width="46"
                          height="8"
                          viewBox="0 0 46 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="46"
                          height="8"
                          viewBox="0 0 46 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>

          <div className="row overflow-hidden" style={{height : "600px"}}>
            <div className="col-lg-8 text-end p-0">
              <img src={industries[activeSlide].image} className="indus-image w-100" />
            </div>

            <div className="col-lg-4  d-flex flex-column justigy-content-center align-items-center d-block d-lg-none mobile-industry-slider">
              <div className="w-100 overflow-hidden">
                <div className="w-100 slider-container">
                  <Slider  {...settings} ref={mobileSliderRef}>
                    {industries.map((item, index) => (
                      <div key={index} className="px-3 ">
                        {/* <ProgressBar/> */}
                        <div className={activeSlide === index ? "row industry-card industry-card-active" : "row industry-card"}>
                          <div className="col-2">
                            <div className={item.class} dangerouslySetInnerHTML={{__html: item.logo}}>
                              {/* <img src={item.logo} className="image" /> */}
                            </div>
                          </div>
                          <div className="col-10 content">
                            <p className="title mb-20">{item.title}</p>
                            <p className="description mb-32">{item.description}</p>
                            <Link className="read-more" to={item.link}> READ MORE <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                              <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                              <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                            </svg></Link>

                          </div>
                        </div>
                        {/* {isHovered} */}
                      </div>
                    ))}
                  </Slider>

                </div>
              </div>

              <div className=" d-flex flex column justify-content-center align-items-center gap-20 mt-15">

                <button
                  onClick={() => mobileSliderRef.current?.slickPrev()}
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="55" viewBox="0 0 78 55" fill="none">
                    <circle cx="27.5" cy="27.5" r="27" transform="matrix(-1 0 0 1 55 0)" fill="white" stroke="#622466" />
                    <path d="M25.6464 27.3536C25.4512 27.1583 25.4512 26.8417 25.6464 26.6464L28.8284 23.4645C29.0237 23.2692 29.3403 23.2692 29.5355 23.4645C29.7308 23.6597 29.7308 23.9763 29.5355 24.1716L26.7071 27L29.5355 29.8284C29.7308 30.0237 29.7308 30.3403 29.5355 30.5355C29.3403 30.7308 29.0237 30.7308 28.8284 30.5355L25.6464 27.3536ZM78 27.5L26 27.5L26 26.5L78 26.5L78 27.5Z" fill="#622466" />
                  </svg>  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                    <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                  </svg>
                </button>

                <button
                  onClick={() => mobileSliderRef.current?.slickNext()}
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="55" viewBox="0 0 78 55" fill="none">
                    <circle cx="50.5" cy="27.5" r="27" fill="white" stroke="#622466" />
                    <path d="M52.3536 27.3536C52.5488 27.1583 52.5488 26.8417 52.3536 26.6464L49.1716 23.4645C48.9763 23.2692 48.6597 23.2692 48.4645 23.4645C48.2692 23.6597 48.2692 23.9763 48.4645 24.1716L51.2929 27L48.4645 29.8284C48.2692 30.0237 48.2692 30.3403 48.4645 30.5355C48.6597 30.7308 48.9763 30.7308 49.1716 30.5355L52.3536 27.3536ZM-3.11023e-08 27.5L52 27.5L52 26.5L3.11023e-08 26.5L-3.11023e-08 27.5Z" fill="#622466" />
                  </svg>
                </button>

              </div>

            </div>

            <div className="col-lg-4 position-relative d-flex flex-column justigy-content-center align-items-center d-none d-lg-block ">

              <div className="position-relative h-100" style={{top : "-60px"}}>



                <div className=" position-absolute top-0 w-100 d-flex justify-content-center flex-column slider-container" style={{height : "90%"}}>

                  <div className="position-relative">

                    <div className="position-absolute w-100 -top-130 d-flex flex column justify-content-center align-items-center gap-20 pt-45 pb-50" style={{right : "30px"}}>

                      <div className="offcanva-btn accent-arrow-btn rotate-180 d-none d-lg-flex">
                        <button
                          className="tp-slider-btn d-inline-block"
                          tabIndex="-1"
                          onClick={() => sliderRef.current?.slickPrev()}
                          type="button"
                        >
                          <span>
                            <svg
                              width="53"
                              height="8"
                              viewBox="0 0 53 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                fill="#7738A5"
                              ></path>
                            </svg>
                            <svg
                              width="53"
                              height="8"
                              viewBox="0 0 53 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                fill="#7738A5"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>

                      <div className="offcanva-btn accent-arrow-btn d-none d-lg-flex">
                        <button
                          className="tp-slider-btn d-inline-block"
                          tabIndex="-1"
                          onClick={() => sliderRef.current?.slickNext()}
                          type="button"
                        >
                          <span>
                            <svg
                              width="53"
                              height="8"
                              viewBox="0 0 53 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                fill="#7738A5"
                              ></path>
                            </svg>
                            <svg
                              width="53"
                              height="8"
                              viewBox="0 0 53 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                fill="#7738A5"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>


                      {/* <button
onClick={() => sliderRef.current?.slickNext()}
type="button"
>
<svg xmlns="http://www.w3.org/2000/svg" width="78" height="55" viewBox="0 0 78 55" fill="none">
  <circle cx="50.5" cy="27.5" r="27" fill="white" stroke="#622466" />
  <path d="M52.3536 27.3536C52.5488 27.1583 52.5488 26.8417 52.3536 26.6464L49.1716 23.4645C48.9763 23.2692 48.6597 23.2692 48.4645 23.4645C48.2692 23.6597 48.2692 23.9763 48.4645 24.1716L51.2929 27L48.4645 29.8284C48.2692 30.0237 48.2692 30.3403 48.4645 30.5355C48.6597 30.7308 48.9763 30.7308 49.1716 30.5355L52.3536 27.3536ZM-3.11023e-08 27.5L52 27.5L52 26.5L3.11023e-08 26.5L-3.11023e-08 27.5Z" fill="#622466" />
</svg>
</button> */}

                    </div>
                  </div>

                  <Slider  {...settings} ref={sliderRef}>
                    {industries.map((item, index) => (
                      <div key={index} className="px-2 ">
                        {/* <ProgressBar/> */}
                        <div className={activeSlide === index ? "row industry-card industry-card-active" : "row industry-card"}>
                          <div className="col-lg-2">
                            <div className={item.class} dangerouslySetInnerHTML={{__html: item.logo}}>
                              {/* <img src={item.logo} className="image" /> */}
                            </div>
                          </div>
                          <div className="col-lg-10 content">
                            <p className="title mb-10">{item.title}</p>
                            <p className="description mb-22">{item.description}</p>
                            <a className="read-more" href={item.link}> READ MORE <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                              <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                              <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                            </svg></a>

                          </div>
                        </div>
                        {/* {isHovered} */}
                      </div>
                    ))}
                  </Slider>

                </div>
              </div>

            </div>
          </div>

          {/* <!-- line  --> */}
          <div className="tp-border-line d-block mb-140">
            <hr />
          </div>
          {/* <!-- line  --> */}
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default TopCtaArea;

import React from 'react';

function OurStory() {
    return (
        <div className=''>
            <section className="">

                {/* <div className='container mx-auto '> */}
                    {/* <div className='title-container' >
                        <h2 className='title m-0'>Our Global Footprint</h2>
                        <h3 className='subtitle m-0'>From a Plastic to whole Market</h3>
                    </div> */}
                    <div className="storyContainer">
        {/* <img src='/assets/img/philoshophy.png' className='max-w-100'/> */}
                    {/* </div> */}

                </div>
            </section>
        </div>

    );
}

export default OurStory;
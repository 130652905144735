import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Recycling() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/recycling.jpg'} title={"Our Product"} subtext={"Masterbatches for Recycling Transforming Waste into Worth"} textAvatar="Re" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of Masterbatches for Recycling</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK’s RE-NEW Masterbatches are specifically designed for the recycling industry to enhance the quality and usability of recycled plastics. Facing challenges such as waste stream segregation and quality degradation, our innovative solutions help improve the processing, properties, and performance of recyclates, making them viable for various high-value applications.</p>
              </div>

              <div className='title-container'>
                <h3 className='title'>Masterbatches for Recycling</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches are designed to enhance the performance and usability of recycled plastics, ensuring high-quality output. Our innovative solutions make recycled materials viable for various high-value applications.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Re Bond</td>
                          <td>Compatibilizers that integrate various polymers in the waste stream, enhancing the quality of the recyclate.</td>
                          <td>Improves material properties and expands the range of recyclable polymers.</td>
                        </tr>
                        <tr>
                          <td>Re Fresh</td>
                          <td>An advanced odor control additive that chemically neutralizes odors rather than merely masking them, ensuring a healthier environment and broader application potential.</td>
                          <td>Keeps processing areas odor-free and enhances the marketability of recycled products.</td>
                        </tr>
                        <tr>
                          <td>Re Nex</td>
                          <td>Works to restore the physical structure of polymers that have been degraded through repeated processing cycles.</td>
                          <td>Increases the structural integrity and performance of recycled plastics.</td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-card-btn'>
              <ProductContact />
            </div>
          </div>
          <div className='col-md-4 mobile-padding'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Recycling